<template>
  <div class="view pa24">
    <div>
      <el-button class="mr10" type="primary" @click="addOptionFun">新增分销设置</el-button>
    </div>
    <commonTable :isTree="true" treeKey="optionId" :treeProps="treeProps" :tableData="tableData" :loading="loading"
      :paginationVisible="false">
      <template v-slot:table>
        <el-table-column prop="fromLevelName" align="left" label="受益等级" show-overflow-tooltip />
        <el-table-column prop="toLevelName" align="center" label="间推" show-overflow-tooltip />
        <el-table-column prop="benefitOwner" align="center" label="受益人" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.level===1">
              {{benefitOwnerData[scope.row.benefitOwner]}}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="money" align="center" label="奖励金额">
          <template slot-scope="scope">
            <div v-if="scope.row.moneyType===0">{{ scope.row.money | getPrice }}</div>
            <div v-if="scope.row.moneyType===1">{{scope.row.money*100}}%</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="costPrice" align="center" label="成本价">
          <template slot-scope="scope">
            {{ scope.row.costPrice | getPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isUse" @change="changeIsUse($event,scope.row)" :active-value="1"
              :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" v-if="scope.row.level===1 && !scope.row.twoMember"
              @click="addChildrenOptionFun(scope.row)">新增二级设置
            </el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="centerDialogVisible" 
      :destroy-on-close="true" width="400px" center>
      <el-form :model="formData" :rules="rules" ref="formData" label-width="110px">
        <el-form-item label="受益等级" prop="fromLevelId">
          <el-select v-model="formData.fromLevelId" :disabled="formType==='update'" filterable remote
            placeholder="请输入关键词" :remote-method="selectAllMemberLevel" :loading="selectLoading">
            <el-option v-for="item in memberLevel" :key="item.levelId" :label="item.levelName" :value="item.levelId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="间推" prop="toLevelId">
          <el-select v-model="formData.toLevelId" :disabled="formType==='update'" filterable remote placeholder="请输入关键词"
            :remote-method="selectAllMemberLevel" :loading="selectLoading">
            <el-option v-for="item in memberLevel" :key="item.levelId" :label="item.levelName" :value="item.levelId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖励金额" prop="money">
          <el-radio-group v-model="moneyType">
            <el-radio-button :label="0">价钱</el-radio-button>
            <el-radio-button :label="1">比例</el-radio-button>
          </el-radio-group>
          <el-input class="w240 suffixInput" v-if="moneyType===0" placeholder="请输入奖励金额" v-model="money" type="number">
            <template slot="append">元</template>
          </el-input>
          <el-input class="w240 suffixInput" v-if="moneyType===1" placeholder="请输入奖励金额" v-model="moneyPercentage"
            type="number">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="成本价" prop="costPrice">
          <el-input class="w240 suffixInput" v-model="formData.costPrice" placeholder="请输入成本价">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="受益人" prop="benefitOwner" v-if="!formData.parentOptionId">
          <el-radio-group v-model="benefitOwner">
            <el-radio-button :label="0">当前会员</el-radio-button>
            <el-radio-button :label="1">二级会员</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUpdateOption">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import commonTable from "@/components/common/commonTable";
import { getMemberDistributorSpecialList, setMemberDistributorSpecial, selectAllMemberLevel } from "@/api/member";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
  },
  data() {
    return {
      loading: false, //表格加载
      centerDialogVisible: false,
      treeProps: { children: "twoMember" },
      tableData: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      modelTitle: "",
      selectLoading: false,
      memberLevel: [],
      moneyType: 0,
      benefitOwner: 0,
      benefitOwnerData: {
        0: "当前会员",
        1: "二级会员"
      },
      moneyPercentage: 0,
      money: 0,
      formData: {
        fromLevelId: "",//当前会员等级id
        toLevelId: "",//推荐会员等级id
        money: 0,
        costPrice: 0,
        parentOptionId: "",
        level: 1,//(1：一级，2：二级）
        moneyType: 0,//(0:价钱，1:比例)
        benefitOwner: 0,//（0：当前会员，1：当前会员的受益人）
      },
      rules: {
        fromLevelId: {
          required: true,
          trigger: "change",
          message: "请选择会员等级",
        },
        toLevelId: {
          required: true,
          trigger: "change",
          message: "请选择直推会员等级",
        },
      },
      formType: "",
      updateId: 0
    };
  },
  filters: {
    getPrice(price) {
      return price + "元";

    },
  },
  watch: {
    moneyType(val) {
      this.formData.moneyType = val;
    },
    benefitOwner(val) {
      this.formData.benefitOwner = val;
    },
  },
  created() {
    this.getMemberDistributorSpecialList();
    this.selectAllMemberLevel();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    changeIsUse(val, row) {
      let params = {
        isUse:val,
        optionId:row.optionId
      };
      setMemberDistributorSpecial(params).then(res => {
        if (res.code === 200) {
        } else {
          this.getMemberDistributorSpecialList();
          this.$message.error(res.message)
        }
      }).catch(err => {
        if (err.code === 201) {
          this.getMemberDistributorSpecialList();
          this.$message.error(err.message)
        }
      })
    },
    /**@method 获取会员等级 */
    selectAllMemberLevel(name) {
      this.selectLoading = true;
      selectAllMemberLevel({
        companyId: this.userInfo.companyId,
        levelName: name,
        pageNum: 1,
        pageSize: 30
      }).then(res => {
        this.selectLoading = false;
        if (res.data) {
          this.memberLevel = res.data.pageInfo.list;
        }
      }).catch(err => {
        this.selectLoading = false;
      })
    },
    addUpdateOption() {
      //修改添加分销设置
      this.$refs.formData.validate((valid) => {
        if (valid) {
          let params = {
            ...this.formData
          };
          if (this.formType === 'update') {
            params.optionId = this.updateId
          }
          if (params.moneyType === 0) {
            params.money = this.money
          } else {
            params.money = this.moneyPercentage / 100;
          }
          setMemberDistributorSpecial(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.centerDialogVisible = false;
                this.getMemberDistributorSpecialList();
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: res.message,
                type: "error",
              });
            });
        }
      });
    },
    /**@method 获取分销设置 */
    async getMemberDistributorSpecialList() {
      let params = {
      };
      try {
        this.loading = true;
        const result = await getMemberDistributorSpecialList(params);
        this.loading = false;

        const { data } = result;
        let tableData = [];
        for (let row of data) {
          if (row.twoMember) {
            row.twoMember = [row.twoMember];
          }
          tableData.push(row);
        }
        this.tableData = tableData;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    addChildrenOptionFun(row) {
      this.centerDialogVisible = true;
      this.modelTitle = "新增二级分销设置";
      this.formType = "add";
      this.formData = {
        fromLevelId: "",//当前会员等级id
        toLevelId: "",//推荐会员等级id
        money: 0,
        costPrice: 0,
        parentOptionId: row.optionId,
        level: 2,//(1：一级，2：二级）
        moneyType: 0,//(0:价钱，1:比例)
        benefitOwner: 0,//（0：当前会员，1：当前会员的受益人）
      };
      this.money = 0;
      this.moneyPercentage = 0;
      this.moneyType = 0;
      this.benefitOwner = 0;
    },
    addOptionFun() {
      this.centerDialogVisible = true;
      this.modelTitle = "新增分销设置";
      this.formType = "add";
      this.formData = {
        fromLevelId: "",//当前会员等级id
        toLevelId: "",//推荐会员等级id
        money: 0,
        costPrice: 0,
        parentOptionId: "",
        level: 1,//(1：一级，2：二级）
        moneyType: 0,//(0:价钱，1:比例)
        benefitOwner: 0,//（0：当前会员，1：当前会员的受益人）
      };
      this.money = 0;
      this.moneyPercentage = 0;
      this.moneyType = 0;
      this.benefitOwner = 0;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(row) {
      this.centerDialogVisible = true;
      this.modelTitle = "编辑分销设置";
      this.formType = "update";
      this.updateId = row.optionId;
      this.formData = {
        fromLevelId: row.fromLevelId,//当前会员等级id
        toLevelId: row.toLevelId,//推荐会员等级id
        money: row.money,
        costPrice: row.costPrice,
        parentOptionId: row.parentOptionId,
        level: row.level,//(1：一级，2：二级）
        moneyType: row.moneyType,//(0:价钱，1:比例)
        benefitOwner: row.benefitOwner,//（0：当前会员，1：当前会员的受益人）
      };
      if (row.moneyType === 0) {
        this.money = row.money;
      } else {
        this.moneyPercentage = row.money * 100
      }
      this.moneyType = row.moneyType;
      this.benefitOwner = row.benefitOwner;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.switchInput {
  display: flex;

  .selectType {
    width: 150px;
  }

  .levelPrice {
    width: 120px;
  }

  .levelTime {
    width: 120px;
  }
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
    width: 95px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}
</style>